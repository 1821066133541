import axios from "../api/HttpService";
import { authHeader } from '../api/HttpService';
import { setProgramsList } from "../reducers/programReducer";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchProgramsDetails = async (dispatch, type, uniqueNonGuessableId = '') => {
  try {
    const response = await axios.get(API_URL + `api/programs?type=${type}&uniqueNonGuessableId=${uniqueNonGuessableId}`, { headers: authHeader() });
	const programs = response.data?.data?.map(({ id, attributes: { name, description, image, order, link, tooltip, title } }) => ({
		  id,
		  name,
		  description,
		  image: image?.data?.attributes?.formats?.thumbnail?.url || image?.data?.attributes?.url,
		  order,
		  link,
		  tooltip,
		  title
	  }))
	  dispatch(setProgramsList(programs));
  } catch (error) {
	console.log('error', error)
  }
};

