import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../sass/layout/programListContainer/programListContainer.css";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";

const ProgramPreviewList = ({ border, program }) => {
  const { t } = useTranslation();
  const tokenMedia = useSelector((state) => state.homeReducer.tokenMedia);

  return (
    <Container fluid className="no-border-container">
      <Row className={border ? "program-item-border" : "program-item"}>
        <img
          style={{
            width: "15em",
            objectFit: "contain",
            padding: "0rem 1.5rem",
          }}
          alt="program"
          src={program?.image + "?" + tokenMedia}
        />
        <Col className="text">
          <h4>{program?.title}</h4>
          <ReactMarkdown children={program?.description} />
          <a href={program?.link} target="_blank">
            See more
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default ProgramPreviewList;
