import "../../sass/layout/article/article.css";
import ArticleContainer from "../articles/articleContainer";
import { useSelector } from "react-redux";
import MenuPath from "../path/MenuPath";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { usePath } from "../../hooks/usePath";
import { Row } from "react-bootstrap";
import { Mixpanel } from "../../utils/mixpanelIntegration";
import UserService from '../../services/api/UserService';

const Article = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const path = usePath();
  const article = useSelector((state) => state.articleReducer.article);
  const location = useSelector((state) => state.homeReducer.location?.data);
  const { id: articleId } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Mixpanel.track("Article.View", { isLogged: UserService.isLoggedIn(), articleId: article?.id, locationName: location?.name });
  }, []);

  const onClickArticleList = () => {
    id !== "/"
      ? navigate({
          pathname: `/${path}/articleslist`,
        })
      : navigate({
          pathname: `/articleslist`,
        });
  };

  const id = useSelector(
    (state) => state.homeReducer.location?.data
  )?.uniqueNonGuessableId;

  return (
    <div>
      <Row className="row-menu-container">
        <MenuPath
          name={t("article.articles")}
          nameItem={article?.title}
          onClickItem={onClickArticleList}
        />
      </Row>
      <ArticleContainer articleId={articleId}/>
    </div>
  );
};

export default Article;
