import "../../sass/layout/programs/programs.css";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";
import chevron from "../../utils/img/chevron-right.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { usePath } from "../../hooks/usePath";
import { useEffect, useState } from "react";
import { ProgramCard } from "./programCard";
import { fetchProgramsDetails } from "../../services/actions/programs.action";

const Programs = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loginStatus = useSelector((state) => state.userReducer.loginStatus);
  const navigate = useNavigate();
  const path = usePath();

  const { programs } = useSelector((state) => ({
    programs: state.programReducer.programsList,
  }));


  useEffect(() => {
    fetchProgramsDetails(dispatch, 'HEALTH');
  }, [dispatch]);


  const [showLeft, setShowLeft] = useState(false);

  return (
    <Container fluid className="container-programs">
      <a
        className="btn-left"
        style={{
          position: "relative",
          top: "6rem",
          right: "3.5rem",
          display: "flex",
          outline: "none",
          width: "max-content",
        }}
        onClick={() => {
          const element = document.getElementsByClassName("overflow-x");
          setShowLeft(false);
          element[0].scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
      >
        <div
          style={{
            width: "50px",
            height: "50px",
            zIndex: "100",
          }}
        >
          {showLeft && (
            <img
              src={chevron}
              style={{
                transform: "rotate(180deg)",
                width: "50px",
                zIndex: "100",
              }}
              alt="arrow"
            />
          )}
        </div>
      </a>
      <a
        className="btn-right"
        style={{
          width: "50px",
          position: "absolute",
          top: "6rem",
          zIndex: "100",
          right: "0",
          paddingLeft: "4rem",
        }}
        onClick={() => {
          setShowLeft(true);
          const element = document.getElementsByClassName("overflow-x");
          const sleft = element[0].scrollLeft;
          element[0].scrollTo({
            top: 0,
            left: sleft + 400,
            behavior: "smooth",
          });
        }}
      >
        <img
          src={chevron}
          style={{
            width: "50px",

            zIndex: "100",
          }}
          alt="arrow"
        />
      </a>

      <h4
        className="programs-title"
        style={
          loginStatus
            ? {
                marginTop: "2rem",
              }
            : {
                marginTop: "-3rem",
              }
        }
      >
        <div>
          <strong>
            {t("path.healthandwellness")} {t("path.programs")}
          </strong>
        </div>
        <div
          onClick={() => {
            navigate(`/${path}/programslist`);
          }}
          className="programs-description"
        >
          {t("programs.viewAll")}
        </div>
      </h4>
      <Row className="overflow-x">
        {programs.map((  { name, image, link, tooltip }) => (
          <ProgramCard
            name={name}
            img={image}
            url={link}
            tooltip={tooltip}
            classOverride={"columna-box"}
          />
        ))}
      </Row>
    </Container>
  );
};

export default Programs;
