import mixpanel from 'mixpanel-browser';
import UserService from '../services/api/UserService';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
const trackingEnabled = process.env.REACT_APP_MIXPANEL_TRACKING_ENABLED;
mixpanel.init(MIXPANEL_TOKEN, { api_host: 'https://mixpanelproxy.miiamemberportal.org' });
// get a variable from the local storage to check if the user events should be tracked or not
const userShouldNotBeTracked = () => localStorage.getItem('userShouldNotBeTracked') === 'true' || localStorage.getItem('userShouldNotBeTracked') === true;





const actions = {
	identify: (userEmail) => {
		if (trackingEnabled && !userShouldNotBeTracked()) mixpanel.identify(userEmail);
		
	},
	track: (action, data) => {
		if (trackingEnabled && !userShouldNotBeTracked()) mixpanel.track(action, { ...data });
	},
	register: (data) => {
		if (trackingEnabled && !userShouldNotBeTracked()) mixpanel.register({ ...data });
	},
};

export const Mixpanel = actions;