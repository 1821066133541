import "../../sass/layout/programsList/programsList.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgramListContainer from "../programs/programsListContainer";
import MenuPath from "../path/MenuPath";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProgramsDetails } from "../../services/actions/programs.action";

const ProgramList = () => {
  const { t } = useTranslation();
  const { programs } = useSelector((state) => ({
    programs: state.programReducer.programsList || [],
  }));

  const location = useSelector((state) => state.homeReducer.location?.data);
  const memberID = location?.uniqueNonGuessableId;

  const dispatch = useDispatch();



  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    fetchProgramsDetails(dispatch, "HEALTH", memberID);
  }, [dispatch, location, memberID]);

  return (
    <div>
      <Row className="row-box-container">
        <Col className="container-data-programs-list">
          <Row className="row-menu-container">
            <MenuPath name={t("programs.programs")} />
          </Row>
          <ProgramListContainer programs={programs} />
        </Col>
      </Row>
    </div>
  );
};

export default ProgramList;
