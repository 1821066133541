// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unauthorized-alert-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3em;
}

.unauthorized-alert {
  width: -moz-fit-content;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/sass/layout/unauthorizedAlert/unauthorizedAlert.scss","webpack://./src/sass/layout/unauthorizedAlert/unauthorizedAlert.css"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;ACCD;;ADEA;EACC,uBAAA;EAAA,kBAAA;ACCD","sourcesContent":[".unauthorized-alert-container{\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\tpadding-top: 3em;\n}\n\n.unauthorized-alert{\n\twidth: fit-content;\n}",".unauthorized-alert-container {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  padding-top: 3em;\n}\n\n.unauthorized-alert {\n  width: -moz-fit-content;\n  width: fit-content;\n}/*# sourceMappingURL=unauthorizedAlert.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
